import React,{useContext} from 'react';
import './styles.scss';
import Img from 'Components/Img';
import { SkeletonImg, SkeletonLine } from 'Components/Skeletion';
import Button from 'Components/Common/Button';
import { Link ,useNavigate} from 'react-router-dom';
import RenderContext from 'Context/RenderContext';
import AdvancedLink from 'Components/AdvancedLink';

const Ecobanner = ({
    loading,
    img,
    title,
    buttonText,
    description,
    button_url,
    backgroundColor
}) => {
    const { loadIfUser, loadPreRender } = useContext(RenderContext);
    const navigate = useNavigate();
    return (
        <div className='ecobanner container px-4 py-6 xl-py-9'>
            <div
                className="wrapper r-5"
                style={{ backgroundColor: backgroundColor }}
            >
                <div className="w-1/1 flex col-i gap-2 lg-flex lg-row lg-gap-2 lg-fillY">
                    <div className="mainCol lg-pt-8 pb-8 lg-pb-10 pl-7 pr-7 lg-pr-0 lg-pl-12">
                        <div className="content-banner flex-1 flex col h-1/1">
                            <div className="banner-info flex-1 flex col">
                                {
                                    loading ?
                                        <>
                                            <SkeletonLine height="56px" animation="pulse" />
                                            <SkeletonLine height="28px" animation="pulse" />
                                        </>
                                        :
                                        <>
                                            <h3 className="ellips line-14 fw-700 pb-4">{title}</h3>
                                            <p className="ellips fs-20 line-9 hide xl-block">{description}</p>
                                        </>
                                }
                            </div>
                            {
                                loading ?
                                    <SkeletonLine
                                        height="48px"
                                        width="50%"
                                        className="r-full"
                                        animation="pulse"
                                    /> :
                                    buttonText ?
                                    <AdvancedLink
                                      className="fw-700 px-3"
                                      to={button_url}
                                      style={{
                                       maxWidth:'fit-content'
                                      }}
                                    >
                                    <Button
                                    size="md"
                                    className="r-full mt-2 lg-mt-6"
                                   
                                    onClick={() => navigate(button_url)}                                   >
                                    
                                      {buttonText}
                                  </Button>
                                  </AdvancedLink>

                                  
                                        : <></>
                            }
                        </div>
                    </div>
                    <div className={`mainCol imgContainer ${loading ? 'block' : 'flex'} overflow-hidden center lg-flex lg-right middle relative zindex-1`}>
                        <div className='lg-tr relative lg-absolute lg-top-0 lg-left-0 flex center lg-flex lg-right w-1/1' style={{maxWidth:"100%", maxHeight:"100%" , height:"100%"}}>
                           <div className='eco_img flex'>

                            {
                                loading ?
                                    <SkeletonImg
                                        className="r-full"
                                        animation="pulse"
                                        style={{
                                            width: "100%",
                                            minHeight: "330px",
                                        }}
                                    />
                                    :
                                    (loadIfUser || loadPreRender) && img ?
                                        <Img
                                            src={img}
                                            alt={title}
                                            type="img"
                                            animation={false}
                                            style={{
                                                maxWidth: "100%",
                                                maxHeight: "100%",
                                                width:"auto",
                                                height:"auto",
                                            }}
                                            className='rtl-4  rtr-4 rbr-0 rbl-0 lg-rtl-0 lg-rtr-4 lg-rbr-4 lg-rbl-0'
                                        /> : <></>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ecobanner;